import { NextRequest, NextResponse } from 'next/server'

import { defaultCustomerSegment } from './middleware-constants'
import { getStoreCodeForMiddleware, STORE_CODES } from '@/common/utils'
import { STORE_CODE_RECORD } from '@/common/constants'
import { Domain, StoreCodeType } from '@/common/types'
import { MiddlewareHeader } from '@/common/types/header-types'
import { Language } from '@/common/types/language-types'
import {
  handleMultipleSlashInPath,
  replaceFirstUaRuLocaleInUrl,
} from '@/common/utils/url-utils'

type SetCommonResponseHeadersProps = {
  href: string
  baseUrl: string
  pathname: string
  customerSegment: string
  xForwardedUrl?: URL
  response: NextResponse
}

export function setCommonResponseHeaders({
  href,
  baseUrl,
  response,
  pathname,
  xForwardedUrl,
  customerSegment,
}: SetCommonResponseHeadersProps) {
  response.headers.set('BaseUrl', baseUrl)
  response.headers.set(MiddlewareHeader.Pathname, pathname)
  response.headers.set(
    MiddlewareHeader.OriginUrl,
    xForwardedUrl?.toString() ?? href,
  )
  response.headers.set(MiddlewareHeader.CustomerSegment, customerSegment)
  response.headers.set(MiddlewareHeader.RequestUrl, href)
}

export function resolveForwardedUrlWithFallback(
  forwardedUrl: URL | undefined,
): string {
  return forwardedUrl
    ? forwardedUrl.toString().replace('http://', 'https://')
    : `${process.env.STORE_PROXY_URL}`
}

export function processMiddlewareHeaders(request: NextRequest) {
  // Get xForwardedUrl from the header, because request.url is always localhost
  // this information is needed to correctly set the storeCode of the application
  const xForwardedUrl = request.headers.get('X-Forwarded-URL')

  // Get url from xForwardedUrl, if xForwardedUrl is not specified (feature-env) use env url
  const forwardedUrl = xForwardedUrl
    ? new URL(xForwardedUrl.replace('http://', 'https://'))
    : undefined

  const storeCodeHeader = request.headers.get('storeCode')
  const storeCodeUrl =
    storeCodeHeader ?? getStoreCodeForMiddleware(forwardedUrl?.toString() ?? '')
  const storeCode =
    (storeCodeHeader as StoreCodeType) ?? (storeCodeUrl as StoreCodeType)
  const token = request.cookies.get('customerToken')?.value
  const customerToken = `Bearer ${token}`

  // if customer is logged in, but we do not have the segment yet, cache it under its token
  const customerSegment =
    request.cookies.get('customerSegment')?.value ??
    customerToken ??
    defaultCustomerSegment

  const { domain, lang: language, locale } = STORE_CODE_RECORD[storeCode]

  // prepare custom headers
  request.headers.set(MiddlewareHeader.Store, storeCode)
  request.headers.set(MiddlewareHeader.RequestUrl, request.url)
  token && request.headers.set(MiddlewareHeader.Authorization, customerToken)

  return {
    forwardedUrl,
    storeCode,
    customerSegment,
    customerToken,
    locale,
    language,
    domain,
  }
}

export const isUA = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES[Domain.UA]

export const isRU = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES[Domain.RU]

export const isUaDomain = (storeCode: StoreCodeType): boolean => {
  return storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru
}

export const getUrlLocale = (language: Language) => {
  switch (language) {
    case Language.Uk:
      return Domain.UA
    case Language.Ru:
      return Domain.RU

    default:
      return language
  }
}

/**
 * @param url - request url to be rewritten in NextResponse
 * @param urlLocale - locale, e.g: `ua`, `sk`, etc.
 * @param subPath - optional part of the path - can be added both with `/`
 * symbol or without it. If passed `/category` or `category`, the url for
 * the category page is modified from `en/weight-loss-supplements` to
 * `en/category/weight-loss-supplements`.
 * @returns URL
 */
export const getRouteUrl = ({
  url,
  urlLocale,
  subPath = '',
}: {
  url: URL
  urlLocale: string
  subPath?: string
}) => {
  const newUrl = new URL(url)

  newUrl.pathname = handleMultipleSlashInPath(
    `${urlLocale}/${subPath}${url.pathname}`,
  )

  if (urlLocale === Domain.UA || urlLocale === Domain.RU) {
    const formattedUrl = new URL(replaceFirstUaRuLocaleInUrl(url.toString()))

    newUrl.pathname = handleMultipleSlashInPath(
      `${urlLocale}/${subPath}${formattedUrl.pathname}`,
    )
  }

  return newUrl
}
