import { useEffect, useState } from 'react'

import { GeoLocationData, getGeoLocation } from '@/api'
import { GB_GEO_LOCATION } from './store-navigator-component'
import type { Store } from '../header/components/language-switcher'
import { getStoreCode } from '@/common/utils'

type UseGeoLocationProps = {
  stores: Store[]
  onNavigatorOpen: () => void
}

export const useGeoLocation = ({ onNavigatorOpen }: UseGeoLocationProps) => {
  const [recommendedStoreCode, setRecommendedStoreCode] = useState('')
  const [geoLocationData, setGeoLocationData] = useState<GeoLocationData>()

  useEffect(() => {
    getGeoLocation()
      .then((response) => {
        const [data] = response.data ?? []
        const timestamp = localStorage.getItem(GB_GEO_LOCATION)
        const hasRecommendedStore = !!data?.recommended_store

        if (hasRecommendedStore) {
          let recommendedUrl = data?.recommended_stores?.[0]?.url ?? ''

          // Remove trailing slash
          if (recommendedUrl.endsWith('/')) {
            recommendedUrl = recommendedUrl.slice(0, -1)
          }

          if (recommendedUrl) {
            setRecommendedStoreCode(getStoreCode(recommendedUrl))
          }

          if ((!timestamp || +timestamp < Date.now()) && hasRecommendedStore) {
            onNavigatorOpen()
          }

          setGeoLocationData(data)
        }
      })
      .catch(console.warn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { geoLocationData, recommendedStoreCode }
}
